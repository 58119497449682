import React, { useEffect, useState, useContext } from 'react'
import { Container, Tab, Nav } from 'react-bootstrap'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import AuthContext from '../../contexts/auth'

const DashboardProfile = () => {
    const [activeKey, setActiveKey] = useState('')
    const location = useLocation()
    const { handleGetDetail } = useContext(AuthContext)

    const initializeKey = () => {
        let activeKey = ''
        if (location.pathname.includes('change-password')) {
            activeKey = 'change-password'
        } else if (location.pathname.includes('account-info')) {
            activeKey = 'account-info'
        } else if (location.pathname.includes('two-factor-authentication')) {
            activeKey = 'two-factor-authentication'
        }

        setActiveKey(activeKey)
    }

    useEffect(() => {
        const fetchDataProfile = async () => {
            try {
                await handleGetDetail()
            } catch (_) {}
        }

        fetchDataProfile()

        return () => {
            fetchDataProfile()
        }
    }, [])

    useEffect(() => {
        initializeKey()
    }, [location.pathname])

    return (
        <Container style={{ marginBottom: '50px' }}>
            <h2 className="mb-4">Profile Saya</h2>
            <Tab.Container activeKey={activeKey}>
                <Nav variant="tabs">
                    <Nav.Item>
                        <Nav.Link as={NavLink} to="account-info" eventKey="admin-info">
                            Informasi Akun                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={NavLink} to="change-password" eventKey="change-password">
                            Ubah Password                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={NavLink} to="two-factor-authentication" eventKey="two-factor-authentication">
                            2FA
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane>
                        <Outlet />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
            <Outlet />
        </Container>
    )
}

export default DashboardProfile
