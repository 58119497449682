import React, { useState, useEffect, useContext } from 'react'
import Axios from 'axios'
import { Row, Col, Card, CardBody, Form, Button, Spinner } from 'react-bootstrap'
import { GetUserQRCode2FA, PutUser2FASetting } from '../../../utils/api'
import AppContext from '../../../utils/context'
import AuthContext from '../../../contexts/auth'
import CustomInput from '../../Shared/CustomInput'
import CustomModal from '../../Shared/CustomModal'
import Guidelines2FA from './Guidelines'

const TwoFactorAuthentication = () => {
    const [selectedStatus2FA, setSelectedStatus2FA] = useState('')
    const [loadingQRCode, setLoadingQRCode] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [twoFactorAuthData, setTwoFactorAuthData] = useState({
        qrcode: '',
        secret: '',
        token_code: ''
    })
    const [openModal, setOpenModal] = useState(false)
    const { pushNotification } = useContext(AppContext)
    const { user, handleGetDetail, handleLogout } = useContext(AuthContext)
    const { Select } = Form

    const onChangeSelectedStatus2FA = (e) => {
        if (e.target.value) {
            setSelectedStatus2FA(e.target.value)
        }
    }

    const handleGetQRCode = async () => {
        try {
            setLoadingQRCode(true)

            const res = await Axios.get(GetUserQRCode2FA())

            if (res.status === 200) {
                setTwoFactorAuthData({
                    ...res.data.data
                })
            }
        } catch (e) {
            pushNotification('error', 'Failed to change password', e)
        } finally {
            setLoadingQRCode(false)
        }
    }

    const handleUpdate2FASetting = async (e) => {
        e.preventDefault()

        try {
            setIsSubmitting(true)

            const res = await Axios.put(PutUser2FASetting(), {
                status: selectedStatus2FA,
                secret: twoFactorAuthData.secret,
                token_code: twoFactorAuthData.token_code
            })
            await handleGetDetail()
            setOpenModal(true)
            setTwoFactorAuthData({
                qrcode: '',
                secret: '',
                token_code: ''
            })
            pushNotification('success', res.data?.message)
        } catch (e) {
            pushNotification('error', 'Failed to update 2FA Setting', e)
        } finally {
            setIsSubmitting(false)
        }
    }

    useEffect(() => {
        if (user.twoFactorAuthentication) {
            setSelectedStatus2FA(user.twoFactorAuthentication)
        }
    }, [user.twoFactorAuthentication])

    useEffect(() => {
        if (selectedStatus2FA === 'active' && !user.secret) {
            handleGetQRCode()
        }
    }, [selectedStatus2FA])

    return (
        <div className="mt-5">
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <form onSubmit={(e) => handleUpdate2FASetting(e)}>
                                <label>Status Two-Factor Authentication</label>
                                <Select
                                    value={selectedStatus2FA}
                                    disabled={loadingQRCode || isSubmitting}
                                    onChange={(e) => onChangeSelectedStatus2FA(e)}
                                    className="my-3"
                                >
                                    <option value="" hidden>
                                        Pilih Status
                                    </option>
                                    <option value="active">Aktif</option>
                                    <option value="inactive">Tidak Aktif</option>
                                </Select>
                                {selectedStatus2FA === 'active' && !user.secret
                                    ? (
                                        <>
                                            <Guidelines2FA qrcode={twoFactorAuthData.qrcode} loadingQRCode={loadingQRCode} />
                                            <Row>
                                                <Col className="px-4">
                                                    <CustomInput
                                                        name="token_code"
                                                        type="text"
                                                        required
                                                        value={twoFactorAuthData.token_code}
                                                        onChange={(e) =>
                                                            setTwoFactorAuthData({
                                                                ...twoFactorAuthData,
                                                                token_code: e.target.value
                                                            })
                                                        }
                                                        disabled={isSubmitting}
                                                        inputClassName="my-3"
                                                        onlyAcceptNumbers
                                                    />
                                                    <Button
                                                        variant="primary"
                                                        type="submit"
                                                        disabled={!twoFactorAuthData.token_code || isSubmitting}
                                                    >
                                                        {isSubmitting && (
                                                            <span className="me-2">
                                                                <Spinner size="sm" animation="border" variant="white" />
                                                            </span>
                                                        )}
                                                        <span>Verifikasi Kode 2FA</span>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                    : (
                                        <Row>
                                            <Col>
                                                <Button variant="primary" type="submit" disabled={isSubmitting} className="my-3 me-3">
                                                    {isSubmitting && (
                                                        <span className="me-2">
                                                            <Spinner size="sm" animation="border" variant="white" />
                                                        </span>
                                                    )}
                                                    <span>Ubah Pengaturan 2FA</span>
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                            </form>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <CustomModal
                show={openModal}
                title={'Sukses Ubah Pengaturan 2FA'}
                keyboard={false}
                closeButton={false}
                primaryButtonText={'Logout'}
                primaryButtonVariant={'outline-danger'}
                primaryButtonAction={() => handleLogout()}
            >
                Status Two-Factor Authentication (2FA) telah diperbarui. Demi alasan keamanan, silakan klik tombol di bawah untuk keluar. Anda perlu masuk kembali untuk menerapkan perubahan tersebut.
            </CustomModal>
        </div>
    )
}

export default TwoFactorAuthentication
